.navbar {
    background-color: @header-bg-color;
    color: @font-color-light;
}

.navbar.nav-despliegue,
.navbar.nav-sensor {
    background-color: @color-primary;
    //font-size: 16px;
    font-size: 1vw;
    font-weight: 500;
    color: @font-color-light;
}

.navbar.nav-despliegue .btn,
.navbar.nav-sensor .btn {
    color: @font-color-light;
}

.navbar.nav-despliegue .center-logo,
.navbar.nav-sensor .center-logo {
    padding-top: 14px;
}

.navbar.nav-sensor {
    background-color: @color-secondary;
}

.navbar-app .btn {
    color: @color-primary;
}

.navbar .center-logo {
    position: absolute;
    width: 70%;
    padding-top: 11px;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
}

.navbar .center-logo img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80px;
}

.has-navbar-top .app-body {
    padding-top: 0 !important;
}

.navbar-form .form-group .form-control.username {
    margin-bottom: 3px;
    background-repeat: no-repeat;
    background-position: 10px;
    padding-left: 40px;
    background-size: 20px;
    background-color: @bg-color-grey !important;
    background-image: url(../images/user_icon.png) !important;
}

.navbar-form .form-group .form-control.password {
    margin-bottom: 3px;
    background-repeat: no-repeat;
    background-position: 10px;
    padding-left: 40px;
    background-size: 20px;
    background-color: @bg-color-grey !important;
    background-image: url(../images/pass_icon.png) !important;
}

.content-header-admin {
    display: inline-block;
}

.main-content .content-header-admin .actions--actions--small {
    width: 100%;
    //background-color:magenta;
    border-color: @bg-color-grey !important;
    border: solid;
}

.content-header-admin ul li {
    display: inline-block;
    height: 6vh;
    border: solid;
    width: 10vw;
    text-align: center;
}

.chatbox {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

.chatbox-header {
    background: @color-primary;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0 0;

    button {
        background-color: @color-secondary-dark;
    }
}

.chatbox-messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    max-height: 300px;
}

.chatbox-message {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
}

.user-message {
    background: @color-primary;
    color: white;
    align-self: flex-end;
}

.system-message {
    background: @color-secondary-dark;
    color: white;
    align-self: flex-start;
}

.chatbox-input {
    display: flex;
    gap: 10px;
    padding: 10px;
    border-top: 1px solid #ccc;
}

.chatbox-input input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
}

.chatbox-input button {
    background: @color-primary;
    color: white;
    border: none;
    text-align: center;
    padding: 12px 12px;
    padding-right: 6px;
    border-radius: 4px;
    cursor: pointer;
}

#icono {
    position: fixed; /* Permite que el icono est� siempre visible en la misma posici�n de la pantalla */
    bottom: 10px; /* Espacio desde la parte inferior de la pantalla */
    right: 10px; /* Espacio desde el lado derecho de la pantalla */
}

.main-content .row .contenedor {
    min-height: 100vh
}

.main-content h1 {
    font-size: 2vw;
}

.main-content h1 strong {
    font-size: 2vw;
}

.main-content h2 {
    font-size: 1.5vw;
}

.input-group {
    /*display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;*/
}

.navbar-brand {
    padding: 7px 0;
}

.navbar-default .navbar-nav > li > a {
    color: @font-color-light;

    &:hover,
    &:focus {
        color: @font-color-light;
    }
}

nav.navbar-default {
    border: none;
}

.navbar-default {

    .navbar-toggle, .navbar-toggle:focus, .navbar-toggle:hover {
        margin-right: 0;
        border: 0;
        background: transparent;

        .icon-bar {
            background-color: @font-color-light;
        }
    }
}

@media (max-width: @screen-sm - 1) {
    .menu-main:not(.authenticated) {
        display: none;
    }
}

.main-content {
    padding-top: 0.01px;
}

.main-content .row-row-eq-height-mt4 li {
    background-color: @bg-color-grey;
    color: @color-primary;
    padding-left: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 15vw;
}

.main-content .row-row-eq-height-mt4 li a {
    color: @color-primary;
}

.main-content .row-row-eq-height-mt4 li:hover {
    background-color: grey;
    color: white;
}

.main-content .row-row-eq-height-mt4 li.active {
    background-color: @color-primary;
    color: white;

    .div-gliph {
        background-color: @color-primary-dark;
    }
}

.main-content .row-row-eq-height-mt4 li #div-gliph.active {
    background-color: @color-primary;
    color: white;

    :hover {
        background-color: @color-primary-dark;
    }
}

.main-content .infraestructura-titulo {
    height: 6vh;
    text-align: center;
    font-size: 18px;
}

.main-content .content-header {
    border-color: @bg-color-grey !important;
    display: flex;
    flex-wrap: wrap;
    //height: 100px;
}

.main-content .content-header .actions--actions--small {
    width: 100%;
    //background-color:magenta;
    border-color: @bg-color-grey !important;
    border: solid;
    display: flex;
    flex-wrap: wrap;
}

.main-content .content-header ul {
    list-style-type: none;
    border-color: @bg-color-grey;
    border: solid;
}


.main-content .content-header .actions--actions--small .actions--left-actions--small {
    width: 10%;
    border-color: @bg-color-grey !important;
    border-right: solid;
    //font-size: 20px;
    font-size: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-content .content-header .actions--actions--small .actions--center-actions--small {
    //width: 90%;
    //overflow: hidden;
    //border-right: solid;
    flex-grow: 1;
    padding-left: 50px;
    display: flex;
    align-items: center;
    //justify-content: center;
}



.main-content .content-header .actions--actions--small .actions--right-actions--small {
    width: 15%;
    border-color: @bg-color-grey !important;
    border-left: solid;
    //font-size: 20px;
    font-size: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-content .content-header .actions--actions--small .actions--right-actions--small .span-admin {
    font-size: 12px;
}

.main-content .content-header .actions--actions--small .actions--right-actions--small .span-admin-tipoGuardar {
    font-size: 12px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .glyphicon {
        width: 10%;
    }
}

.main-content .content-header .actions--actions--small .actions--right-actions--small .span-admin-tipot,
.main-content .content-header .actions--actions--small .actions--left-actions--small .span-admin-tipot {
    font-size: 12px;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;

    i {
        padding-right: 10px;
        padding-bottom: 2%;
        font-size: 22px;
    }
}

main-content .content-header .actions--actions--small .actions--left-actions--small {
    font-size: 12px;
    //background-color: @color-primary;
}


/*.main-content .content-header .actions--actions--small .actions--left-actions--small:hover {
width: 10%;
border-color: @bg-color-grey !important;
border-right: solid;
//font-size: 20px;
font-size: 1vw;
display: flex;
align-items: center;
justify-content: center;
background-color: @color-primary;
color: white;
}*/

.main-content .content-header .actions--actions--small .actions--right-actions--small:hover {
    border-left: solid;
    border-color: @bg-color-grey !important;
    //font-size: 20px;
    font-size: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @color-primary;
    color: white;
}

.main-content .row-row-eq-height-mt4 ul li {
    height: 100%;
    position: relative;
    //background-color: greenyellow;
}

.main-content .row-row-eq-height-mt4 ul li .div-gliph {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #C2C2C2;
    width: 20%;
    display: flex;
}

.main-content .row-row-eq-height-mt4 ul {
    :not(.active):hover {
        background-color: #341BD6;

        .div-gliph {
            background-color: #0F1047;

            :not(.active):hover {
                background-color: #0F1047;
            }
        }

        div {
            background-color: #341BD6;

            :not(.active):hover {
                background-color: #341BD6;
            }
        }
    }

    .active:hover {
        background-color: @color-primary;

        .div-gliph {
            background-color: @color-primary-dark;
        }

        div-gliph.active:hover {
            background-color: @color-primary-dark;
        }

        div {
            background-color: @color-primary;

            :not(.active):hover {
                background-color: @color-primary;
            }
        }
    }
}


.main-content .row-row-eq-height-mt4 ul li .div-gliph .glyphicon-asterisk, .glyphicon-list-alt, .glyphicon-wrench, .glyphicon-check, .glyphicon-print, .glyphicon-floppy-disk, glyphicon-lock, .glyphicon-qrcode, .glyphicon-upload {
    height: 100%;
    width: 100%;
    display: flex;
    font-size: 18px;
    justify-content: center;
    align-items: center;
}

.main-content .row-row-eq-height-mt4 ul li div {
    align-items: center;
    justify-content: center;
    align-self: stretch;
    display: flex;
}

.main-content .row-row-eq-height-mt4 ul {
    display: inline;
}


.main-content .row-row-eq-height-mt4 ul li .p-corta {
    font-size: 0.8vw;
}

.main-content .row-row-eq-height-mt4 ul li .p-larga, .container-fluid-infraestructura .tabs-content #tabinsp .container-body .p-larga {
    text-align: center;
    font-size: 0.8vw;
}


.main-content .row-row-eq-height-mt4 form .row #col-tipotajo {
    width: 20%;
}


.main-content .row-row-eq-height-mt4 #tajo form .col-xs-8 .row .col-xs-4 #boton-subir {
    height: 50px;
    width: 75%;
}

.main-content .row-row-eq-height-mt4 #tajo form .row .col-xs-4 #boton-subir-tajo {
    height: 50px;
    width: 25%;
}

.main-content .row-row-eq-height-mt4 #tajo form .panel--border-bottom .row-tajos {
    display: inline-flex;
    width: 100%;
}

.main-content .row-row-eq-height-mt4 #tajo form .panel--border-bottom .row-tajos .col-xs-4-boton {
    width: 30px;
}

.main-content .row-row-eq-height-mt4 #tajo form .panel--border-bottom .row-tajos .col-xs-4-boton .boton-subir-tajo .btn-primary {
    align-content: center;
    margin-top: 25px;
    //color: magenta;
}

.main-content .row-row-eq-height-mt4 #tajo form .col-xs-8 .row .col-xs-4 #boton-subir {
    //margin-top:8%;
    margin-top: 25px;
    //color: yellow;
}

.main-content .row-row-eq-height-mt4 #tajo form .panel--border-bottom .row-tajos .col-xs-2 .form-group #boton-subir {
    align-content: center;
    margin-top: 25px;
}


.main-content .row-row-eq-height-mt4 #tajo form .col-xs-8 .row .col-xs-4 #boton-subir #boton-molesto {
    opacity: 0;
    width: 100%;
    height: 100%;
    margin-top: 30px;
    //color: yellow;
}

.main-content .row-row-eq-height-mt4 #tajo form .documentacion-explorer .btn-group .boton-add-tajo {
    background-color: @color-primary !important;
    color: @font-color-light;
}

.main-content .row-row-eq-height-mt4 #tajo form #lista-imagenes-parte {
    display: inline-block;
}

.main-content .row-row-eq-height-mt4 #tajo form #lista-imagenes-parte .imagen-parte {
    max-width: 300px;
    max-height: 300px;
}

.main-content .form-control {
    background-color: @bg-color-grey !important;
    color: @color-primary;
}

.main-content #informeseguimiento .col-xs-3 button {
    margin-top: 25px;
    background-color: @color-primary;
    color: @color-secondary;
}

.main-content #informeseguimiento .col-xs-3 .dropdown-menu {
    width: 90%;
}

.main-content #informeseguimiento .col-xs-3 .dropdown-menu li {
    background-color: white;
    //height:100px;
}

.main-content #informeseguimiento .col-xs-3 .dropdown-menu table button {
    margin-top: 0px;
    background-color: @color-primary;
}

.main-content #general .col-xs-8 button {
    //margin-top: 25px;
    background-color: @color-primary;
    color: @color-secondary;
}

.main-content #general .col-xs-8 .dropdown-menu {
    width: 100%;
}

.main-content #general .col-xs-8 .dropdown-menu li {
    background-color: white;
    //height:100px;
}

.main-content #general .col-xs-8 .dropdown-menu table button {
    margin-top: 0px;
    background-color: @color-primary;
}

.main-content #general .col-xs-4 .col-xs-6 {
    display: inline table;
}

.main-content #general .col-xs-4 .col-xs-6 label {
}

.main-content #general .col-xs-4 .col-xs-6 label .texto-radio {
    //background-color: white;
    margin-top: 15px;
}




@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid @color-primary; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

.main-content .table1200 {
    border: solid;
    width: 1200px;
    text-align: center;

    td {
        border: solid;
    }

    .tituloTabla {
        width: 100%;

        td {
            background-color: @color-primary-dark;
            color: @color-secondary;
            border-color: @color-secondary;
        }

        .td33 {
            width: 33%;
        }

        .td22 {
            width: 22%;
        }

        .td11 {
            width: 11%;
        }

        .td15 {
            width: 15%;
        }

        .td25 {
            width: 25%;
        }

        .td50 {
            width: 50%;
        }

        .td80 {
            width: 80%;
        }

        .td25 {
            width: 25%;
        }
    }

    .TablaTitulos {
        border: solid;
        width: 100%;
        background-color: @color-primary;
        color: @color-secondary;
        border-color: @color-secondary;

        .td60 {
            width: 60%;
        }

        .td20 {
            width: 20%
        }

        .td50 {
            width: 50%;
        }
    }

    .TablaDatos {
        border: solid;
        width: 100%;

        .td60 {
            width: 60%;
        }

        .td10 {
            width: 10%;
        }

        .td20 {
            width: 20%
        }

        .td50 {
            width: 50%;
        }

        .td25 {
            width: 25%;
        }
    }
}

.main-content .table3000 {
    border: solid;
    width: 3000px;
    text-align: center;

    td {
        border: solid;
    }

    .tituloTabla {
        td {
            background-color: @color-primary-dark;
            color: @color-secondary;
            border-color: @color-secondary;
        }

        .td33 {
            width: 33%;
        }

        .td22 {
            width: 22%;
        }

        .td11 {
            width: 11%;
        }
    }

    .TablaTitulos {
        border: solid;
        width: 100%;
        background-color: @color-primary;
        color: @color-secondary;
        border-color: @color-secondary;

        .td60 {
            width: 60%;
            height: 100%;
        }

        .td20 {
            width: 20%;
            height: 100%;
        }

        .td50 {
            width: 50%;
            height: 100%;
        }

        .t40 {
            width: 40%;
            height: 100%;
        }

        .td125 {
            width: 12.5%;
        }

        .td625 {
            width: 6.25%;
        }

        .tableBosc {
            width: 100%;
        }

        .td435 {
            width: 4.35%;
        }

        .td348 {
            width: 34.8%;
        }

        .td2175 {
            width: 21.75%;
        }

        .td87 {
            width: 8.7%;
        }
    }

    .TablaDatos {
        border: solid;
        width: 100%;


        .td60 {
            width: 60%;
        }

        .td10 {
            width: 10%;
        }

        .td20 {
            width: 20%
        }

        .td50 {
            width: 50%;
        }

        .td125 {
            width: 12.5%;
        }

        .td625 {
            width: 6.25%;
        }

        .td435 {
            width: 4.35%;
        }

        .td348 {
            width: 34.8%;
        }

        .td2175 {
            width: 21.75%;
        }

        .td87 {
            width: 8.7%;
        }
    }
}

.main-content .table2000 {
    border: solid;
    width: 2000px;
    text-align: center;

    td {
        border: solid;
    }

    .tituloTabla {
        width: 100%;

        td {
            background-color: @color-primary-dark;
            color: @color-secondary;
            border-color: @color-secondary;
        }

        .td33 {
            width: 33%;
        }

        .td22 {
            width: 22%;
        }

        .td11 {
            width: 11%;
        }

        .td15 {
            width: 15%;
        }

        .td25 {
            width: 25%;
        }

        .td125 {
            width: 12.5%;
        }

        .td50 {
            width: 50%;
        }

        .td80 {
            width: 80%;
        }

        .td875 {
            width: 8.75%;
        }
    }

    .TablaTitulos {
        border: solid;
        width: 100%;
        background-color: @color-primary;
        color: @color-secondary;
        border-color: @color-secondary;

        .td60 {
            width: 60%;
        }

        .td20 {
            width: 20%
        }

        .td50 {
            width: 50%;
        }
    }

    .TablaDatos {
        border: solid;
        width: 100%;

        .td60 {
            width: 60%;
        }

        .td10 {
            width: 10%;
        }

        .td20 {
            width: 20%
        }

        .td50 {
            width: 50%;
        }
    }
}

.main-content .table500 {
    width: 500px;

    td {
        border: solid
    }

    .tdLateral {
        background-color: @color-primary-dark;
        color: @color-secondary;
        border-color: @color-secondary;
    }
}

.main-content .table800 {
    border: solid;
    width: 800px;
    text-align: center;

    td {
        border: solid;
    }

    .tituloTabla {
        width: 100%;

        td {
            background-color: @color-primary-dark;
            color: @color-secondary;
            border-color: @color-secondary;
        }

        .td45 {
            width: 4.5%;
        }

        .td9 {
            width: 9%;
        }

        .td225 {
            width: 22.5%;
        }

        .td36 {
            width: 36%;
        }

        .td33 {
            width: 33%;
        }

        .td22 {
            width: 22%;
        }

        .td11 {
            width: 11%;
        }

        .td15 {
            width: 15%;
        }

        .td25 {
            width: 25%;
        }

        .td125 {
            width: 12.5%;
        }

        .td50 {
            width: 50%;
        }

        .td80 {
            width: 80%;
        }

        .td875 {
            width: 8.75%;
        }
    }

    .TablaTitulos {
        border: solid;
        width: 100%;
        background-color: @color-primary;
        color: @color-secondary;
        border-color: @color-secondary;

        .td60 {
            width: 60%;
        }

        .td20 {
            width: 20%
        }

        .td50 {
            width: 50%;
        }

        .td45 {
            width: 4.5%;
        }

        .td9 {
            width: 9%;
        }

        .td225 {
            width: 22.5%;
        }

        .td36 {
            width: 36%;
        }
    }

    .TablaDatos {
        border: solid;
        width: 100%;

        .td60 {
            width: 60%;
        }

        .td10 {
            width: 10%;
        }

        .td20 {
            width: 20%
        }

        .td50 {
            width: 50%;
        }

        .td45 {
            width: 4.5%;
        }
    }
}

.main-content .row .graficos {
    //background-color: magenta;
    .grafico-seccion-completa {
        width: 50%;
    }

    .grafico-seccion {
        padding-top: 30px;

        .grafico-seccion-contenido {
            display: inline-flex;

            table {
                th {
                    background-color: @color-primary;
                    color: @color-secondary;
                    width: 250px;
                    text-align: center;
                }

                td {
                    width: 250px;
                    text-align: center;
                }

                .Leve {
                    background-color: green;
                }

                .Moderado {
                    background-color: yellow;
                }

                .Severo {
                    background-color: orange;
                }

                .Critico {
                    background-color: red;
                }
            }
        }
    }
}

.main-content .row .container-seccion {
    width: 30%;

    .range-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
    }

    input[type="range"] {
        width: 100%;
        margin: 0 10px;
        position: relative;
    }

    .tooltip {
        position: absolute;
        background: #007bff;
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;
        transform: translateX(-50%);
        z-index: 10;
        pointer-events: none; /* Para que no interfiera con la interacci�n */
    }

    .number-display {
        font-size: 24px;
        font-weight: bold;
        margin-top: 10px;
        color: #007bff;
    }

    .min-max {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        margin-top: 5px;
    }
}
