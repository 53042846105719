@font-face {
  font-family: 'Capian';
  src: url('../fonts/Capian-Bold.eot');
  src: url('../fonts/Capian-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Capian-Bold.woff2') format('woff2'),
      url('../fonts/Capian-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;   
}

@font-face {
  font-family: 'Capian';
  src: url('../fonts/Capian-Regular.eot');
  src: url('../fonts/Capian-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Capian-Regular.woff2') format('woff2'),
      url('../fonts/Capian-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('../fonts/SinkinSans-400Regular.eot');
  src: url('../fonts/SinkinSans-400Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/SinkinSans-400Regular.woff2') format('woff2'),
      url('../fonts/SinkinSans-400Regular.woff') format('woff'),
      url('../fonts/SinkinSans-400Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sinkin Sans';
  src: url('../fonts/SinkinSans-700Bold.eot');
  src: url('../fonts/SinkinSans-700Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/SinkinSans-700Bold.woff2') format('woff2'),
      url('../fonts/SinkinSans-700Bold.woff') format('woff'),
      url('../fonts/SinkinSans-700Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


html, body {
  font-size: 14px;
}

body {
  background-color: @bg-color;
  color: @font-color-base;
  font-family: "Sinkin Sans", sans-serif;
  font-weight: @font-weight-regular;
  line-height: @line-height-base;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;

  input[type=text],
  input[type=email],
  input[type=tel],
  input[type=url],
  input[type=search] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  }

  input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    background-color: transparent !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: @font-color-light;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

* {
  &:focus {
    outline: 0 !important;
  }
}

a {
  &:hover, &:focus {
    color: inherit;
  }
}