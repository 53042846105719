#masthead {
    > .navbar {
        box-shadow: @header-box-shadow;
    }

    .container > .navbar-header {
        margin-left: 0;
        margin-right: 0;

        > .navbar-brand {
            margin-left: 0;
        }

        > .navbar-right {
            margin-right: 0;
        }
    }
}

.navbar.navbar-default {
    border-radius: 0;
    margin: 0;
    min-height: 70px;

    .navbar-center {
        min-height: 70px;
        padding-left: 73%;

        button {
            background-color: transparent;
            color: white;
            min-height: 70px;
            width: 200px;
            font-size: 13px;
            border: none;
            font-weight: bold;
            text-transform: uppercase;
            
            .glyphicon-user{

                padding-right:10px;
                font-size:20px;
            }

            &:hover {
                background-color: #957600;
            }

            span {
                font-size: @font-family-base;
            }
        }
    }

    .container-fluid {
        @media screen and (min-width: 768px) {
            // padding-right: 0;
        }

        @media (max-width: 768px) {
            > .navbar-header {
                display: inline-block;
            }

            > .navbar-nav.navbar-right {
                float: right;
                margin-right: 0;
                background-color:magenta;
            }
        }
    }

    .navbar-brand {
        height: 70px;

        img {
            max-width: 100%;
            max-height: 100%;
            padding: 5px 30px;
        }
    }

    .navbar-toggle {
        height: 70px;
        margin: 0;
    }
}

#navbar-collapse-main {
  // padding: 0;

  @media screen and (min-width: 768px) {
    display: flex !important;    
    padding-left: 15px;
    padding-right: 15px;
  }

  ul.navbar-center {
    float: none;
    display: flex;
    justify-content: center;
    flex: 1;

    @media screen and (max-width: 768px) {
      margin: 0;
      flex-direction: column;

      li {
        min-width: 0;

        a {
          border: none;
          height: auto;
        }
      }
    }
  }

  ul li {
    min-width: 130px;

    a {
      height: 70px;
      display: flex;
      align-items: center; 
      border-right: 1px solid @color-primary;
      border-left: 1px solid @color-primary;
      font-size: 1rem;
      font-weight: bold;
      transition: all 0.2s;

      &:hover {
        background-color: @color-primary;
        color: white;
      }
    }

    &.active {
      a {
        background-color: @color-primary;
        color: white;
      }
    }

    &.dropdown {
      min-width: 0;
      a {
        border: none;  
      }
    }
  } 
}

.infraestructura .row-header {
  box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.4)
}

.infraestructura-header {
    display: flex;

    .infraestructura-header-datos {
        flex: auto;
        padding-right: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 30px 10px 0;

        .infraestructura-box-Cod {
            text-align: center;
            background: @color-ok-light;
            color: @color-ok;
            border-radius: 15px;
            padding: 7px 15px 3px;
            font-size: 1.1rem;
            font-weight: 700;
            text-transform: uppercase;
            margin: 0 auto 15px 0;

            &.infraestructura-status-0 {
                background: @color-ok-light;
                color: @color-ok;
            }

            &.infraestructura-status-1 {
                background: @color-warning-light;
                color: @color-warning;
            }

            &.infraestructura-status-2 {
                background: @color-fail-light;
                color: @color-fail;
            }
        }

        .infraestructura-box-Descripcion {
            font-size: 1.1rem;
            margin-bottom: 20px;
        }
    }

    .infraestructura-header-meta {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 992px) {
    .infraestructura {
      .infraestructura-header-img,
      .infraestructura-box-Descripcion,
      .infraestructura-header-meta {
        display: none;
      }

      .infraestructura-header-datos {
        padding: 15px;
        text-align: center;

        h1 {
          margin: 0 !important;
        } 
      } 
    }
}

.header-infra {
    width: calc(100% - 170px);
    position: absolute;
    left: 85px;
    height: 70px;

    ul {
        display: flex;
        justify-content: space-between;
        height: 69px;
        width: 100%;
        //max-width: 1170px;
        width: 68%;
        margin: 0 auto;
        border: none;

        li {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 15px;
            border-left: 1px solid #2A15AE;
            transition: all 0.2s;
            background: @color-primary;

            &:hover {
                cursor: pointer;
                background-color: #2A15AE;
            }

            &.active {
                //background-color: #2A15AE;
                background-color: @color-primary-dark;
                color: @color-secondary;
            }

            &:last-child {
                border-right: 1px solid #2A15AE;
                ;
            }
        }
    }

    @media (max-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 0;
        display: block;
        z-index: 999999;
        height: 50px;
        background-color: @color-primary;
        overflow: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }

        ul {
            height: 49px;
            justify-content: unset;

            li {
                padding-right: 20px;
                padding-left: 19px;
                flex: 0;
            }
        }
    }
}

.navbar-nav.navbar-right {
    width: 20%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 10px;
    font-weight: bold;
    //background-color: #957600;

    .glyphicon-cog {
        padding-top: 20px;
        padding-right: 10px;
        color: white;
        background-color: #957600;
    }

    button.dropdown-toggle {
        background: transparent;
        border: none;

        span {
            color: white;
            font-size: 20px;
            font-weight: 100;
            transform: scaleX(1);
        }
    }

    .dropdown {
        background-color: #957600;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        /*button {
            background-color: transparent;
            color: white;
            min-height: 70px;
            width: 200px;
            font-size: 13px;
            border: none;
            font-weight: bold;
            text-transform: uppercase;

            .glyphicon-user {
                padding-right: 10px;
                font-size: 20px;
            }

            &:hover {
                background-color: #957600;
            }

            span {
                font-size: @font-family-base;
            }
        }*/
    }

    #dropdown-usuario {
        background-color: transparent;
        justify-content: center;
        align-items: center;


        &:hover {
            background-color: #957600;
        }

        button {
            text-transform: uppercase;

            .glyphicon-user {
                padding-top: 20px;
                padding-right: 10px;
                padding-left: 20px;
                font-size: 20px;
            }

            span {
                font-size: @font-family-base;
            }
        }
    }

    #dropdown-admin {
        &:hover {
            background-color: @color-primary;

            span {
                background-color: @color-primary;
            }
        }
    }
}
