:root {
    --infra-header: 100px;
}

// Colors
@color-primary: #372fb5;
@color-primary-dark: #231C86;
@color-primary-ligth: #2915A9;
@color-primary-xlight: #F0EFFF;
@color-secondary: #ffce00;
@color-secondary-light: #EFEBDA;
@color-secondary-dark: #957600;

@color-alert: #ff6903;
@color-success: #03d8d8;
@color-muted: #B0C3C3;

@color-ok: #13B23D;
@color-warning: #FFCE00;
@color-fail: #C63737;
@color-ok-light: #D2FCDC;
@color-warning-light: #FFF9C9;
@color-fail-light: #FCCBCB;

@color-green: @color-success;
@color-orange: #ff9933;

@font-color-base: #231C86;
@font-color-light: #fff;
@font-color-dark: #000;
@font-color-grey: #626971;

@bg-color: #fff;
@bg-color-light: #fff;
@bg-color-dark: #000;
@bg-color-grey: #eaeaea;

@border-color-base: #3B434E;
@border-color-grey: @font-color-grey;

@header-bg-color: @color-primary-dark;


// Typography
@font-family-base: "Rajdhani", sans-serif;

@line-height: 1.5;

@font-weight-light: 400;
@font-weight-regular: 500;
@font-weight-bold: 600;

// Titles
@title-height: 80px;

// Box shadow
@header-box-shadow: 0 2px 20px rgba(@bg-color-dark, .5);
@cards-box-shadow: 0 1px 5px rgba(@bg-color-dark, .25);
@cards-box-shadow-hover: 0 3px 15px rgba(@bg-color-dark, .25);

// Border radius
@border-radius-base: 0;
@border-radius-sm: 0;
@border-radius-lg: 0;