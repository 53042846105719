.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;

  > li {
    display: inline;

    > a,
    > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      line-height: 1.42857143;
      text-decoration: none;
      color: @font-color-base;
      background-color: @bg-color-light;
      border: 1px solid @bg-color-grey;
      margin-left: -1px;

      &:hover,
      &:focus {
        color: @font-color-base;
        background-color: @bg-color-grey;
        border-color: @bg-color-grey;
      }
    }

    &:first-child {
      > a,
      > span {
        margin-left: 0;
        border-top-left-radius: @border-radius-base;
        border-bottom-left-radius: @border-radius-base;
      }
    }

    &:last-child {
      > a,
      > span {
        margin-left: 0;
        border-top-right-radius: @border-radius-base;
        border-bottom-right-radius: @border-radius-base;
      }
    }
  }

  > .active {
    > a,
    > span,
    > a:hover,
    > span:hover,
    > a:focus,
    > span:focus {
      z-index: 2;
      color: @font-color-base;
      background-color: @bg-color-grey;
      border-color: @bg-color-grey;
      cursor: default;
    }
  }
}

.pagination-lg {
  > li {
    > a,
    > span {
      padding: 10px 16px;
      font-size: 18px;
    }

    &:first-child {
      > a,
      > span {
        border-top-left-radius: @border-radius-lg;
        border-bottom-left-radius: @border-radius-lg;
      }
    }

    &:last-child {
      > a,
      > span {
        border-top-right-radius: @border-radius-lg;
        border-bottom-right-radius: @border-radius-lg;
      }
    }
  }
}

.pagination-sm {
  > li {
    > a,
    > span {
      padding: 5px 10px;
      font-size: 14px;

      @media (max-width: @screen-xs - 1) {
        padding: 5px 8px;
        font-size: 15px;
      }
    }

    &:first-child {
      > a,
      > span {
        border-top-left-radius: @border-radius-sm;
        border-bottom-left-radius: @border-radius-sm;
      }
    }

    &:last-child {
      > a,
      > span {
        border-top-right-radius: @border-radius-sm;
        border-bottom-right-radius: @border-radius-sm;
      }
    }
  }
}