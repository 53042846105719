.indicador {
  width: 100%;
  background-color: @bg-color-light;
  min-height: 55px;
  height: auto;
  background-repeat: no-repeat;
  background-position: 96% 50%;
  background-size: 28px;
  margin-bottom: 20px;
  padding: 2px;

  @media (max-width: @screen-xs - 1) {
    padding: 0;
  }
}

div.indicador {
  box-shadow: @cards-box-shadow;
}

.sensor-detalle .indicador {
  box-shadow: none;
}

.indicador-titulo {
  padding: 10px;
}

.indicador-titulo .indicador-icon {
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  @media (max-width: @screen-xs - 1) {
    background-size: 40px;
  }
}

.sensor-detalle .indicador {
  background-position: 98% 50%;
  background-size: 36px;
}

.sensor-detalle .indicador.icon_agua, .table .indicador td.icon_agua, .indicador-titulo .icon_agua {background-image: url(../images/icon-agua.svg);}
.sensor-detalle .indicador.icon_aire, .table .indicador td.icon_aire, .indicador-titulo .icon_aire {background-image: url(../images/icon-aire.svg);}
.sensor-detalle .indicador.icon_fauna, .table .indicador td.icon_fauna, .indicador-titulo .icon_fauna {background-image: url(../images/icon-fauna.svg);}
.sensor-detalle .indicador.icon_geologia, .table .indicador td.icon_geologia, .indicador-titulo .icon_geologia {background-image: url(../images/icon-geologia.svg);}
.sensor-detalle .indicador.icon_residuos, .table .indicador td.icon_residuos, .indicador-titulo .icon_residuos {background-image: url(../images/icon-residuos.svg);}
.sensor-detalle .indicador.icon_ruido, .table .indicador td.icon_ruido, .indicador-titulo .icon_ruido {background-image: url(../images/icon-ruido.svg);}
.sensor-detalle .indicador.icon_suelos, .table .indicador td.icon_suelos, .indicador-titulo .icon_suelos {background-image: url(../images/icon-suelo.svg);}
.sensor-detalle .indicador.icon_vegetacion, .table .indicador td.icon_vegetacion, .indicador-titulo .icon_vegetacion {background-image: url(../images/icon-vegetacion.svg);}
.sensor-detalle .indicador.icon_vibraciones, .table .indicador td.icon_vibraciones, .indicador-titulo .icon_vibraciones {background-image: url(../images/icon_vibracion.png);}
.sensor-detalle .indicador.icon_panoramica, .table .indicador td.icon_panoramica, .indicador-titulo .icon_panoramica {background-image: url(../images/icon-panoramica.svg);}

.indicador .nombre {
  font-size: 18px;
  color: @font-color-base;
  padding-right: 60px;
  text-transform: uppercase;
  font-weight: @font-weight-bold;
}
.indicador .unidades {
  font-size: 14px;
  color: @color-primary;
  padding-right: 60px;
  background-repeat: no-repeat;
  background-position: 0% 48%;
  background-size: 18px;
}

.table .indicador td {
  padding: 10px 15px 7px;
  vertical-align: middle;
}

.table .indicador td.nombre {
  font-size: 16px;
}

.table .indicador td.icono {
  background-repeat: no-repeat;
  background-position: 10%;
  background-size: 22px auto;
  padding-left: 50px;

  @media (max-width: @screen-xs - 1) {
    padding-left: 40px;
  }
}

.table .indicador td.desc {
  @media (max-width: @screen-xs - 1) {
    font-size: 13px;
    min-width: 250px;
  }
}

.indicadores {
  margin-bottom: 3px;
}