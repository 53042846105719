﻿.rv-wrapper {
    background-image: url(../images/bg-seleccion.jpg);
    align-items: center;
    background-position: center;
    background-size: cover;
    display: flex;
    flex: 1;
    background-repeat: no-repeat;
    height: 100%;

    .rv-container {
        max-width: 600px;
        text-align: center;
        color: white;
        padding: 30px 10px;

        p {
            margin-top: 30px;
            font-size: 1.1rem;
        }
    }
}
