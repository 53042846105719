footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    margin-bottom: 0;
    background: transparent;
    color: @font-color-light;
    mix-blend-mode: difference;
    padding-top: 15px;
    padding-bottom: 15px;

    p:last-child {
        margin-bottom: 0;
    }
}

.footer-center {
    margin-left: auto;
    margin-right: auto;
}

.footer-right {
    float: right;
    padding-right: 20px;
}

.footer-left {
    float: left;
    padding-left: 20px;
}

.about img {
    max-width: 100%;
}

footer a,
footer a:hover,
footer a:visited,
footer a:active {
    color: @font-color-light;
    mix-blend-mode: difference;
}

footer {
    color: @font-color-light;
    mix-blend-mode: difference;
    text-align: center;
    margin-top: 50px;
}

footer hr {
    color: @font-color-light;
    mix-blend-mode: difference;
}
