.btn {
    background-color: @color-primary;
    color: @color-secondary;

    &-primary {
        .button-variant(@font-color-light; @color-primary; @color-primary);
    }

    &-secondary {
        .button-variant(@font-color-light; @color-secondary; @color-secondary);
    }

    &-full {
        width: 100%;
    }

    &-bottom {
        //position: absolute;
        position: fixed;
        height: 50px;
        bottom: 0;
        width: 100%;

        .btn50 {
            width: 50%;
            display: inline-block;
            height: 100%;
            text-align: center;
            padding-top: 15px;
            background-color: @color-secondary;
            color: @font-color-light;
            text-transform: uppercase;
        }

        .btn50.active {
            background-color: @color-primary;
        }

        &.verde .btn50 {
            background-color: @color-success;
        }

        &.verde .btn50.active {
            background-color: @color-secondary;
        }
    }

    &-admin {
        background-color: @color-primary;

        &:hover,
        &:active,
        &:focus {
            background-color: @color-secondary !important;
        }
    }
}

.btn-gravedad-critico {
    background-color: red; // Define un color peligroso
    color: black; // Asegura que el texto sea visible
    &:hover {
        background-color: darkred;
    }
}

.btn-gravedad-severo {
    background-color: orange; // Define un color de advertencia
    color: black;

    &:hover {
        background-color: sandybrown;
    }
}

.btn-gravedad-moderado {
    background-color: yellow; // Define un color informativo    color: black;
    color: black;

    &:hover {
        background-color: gold;
    }
}

.btn-gravedad-leve {
    background-color: green; // Define un color de �xito
    color: black;

    &:hover {
        background-color: darkgreen;
    }
}
