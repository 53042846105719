.list-infra {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .infra {
        /*flex: 0 0 calc(33.333333% - 40px);*/
        flex: 30%;
        background: rgb(35,28,134);
        background: linear-gradient(180deg, rgba(35,28,134,1) 0%, rgba(28,22,111,1) 100%);
        border: none;
        border-radius: 5px;
        overflow: hidden;
        -webkit-box-shadow: 3px 3px 6px 2px rgba(0,0,0,0.45);
        box-shadow: 3px 3px 6px 2px rgba(0,0,0,0.45);
        display: flex;
        justify-content: center;
        flex-direction: column;

        &-images {
            text-align: center;
            position: relative;
            background: @color-primary-ligth;
            padding: 40px;
            transition: all 0.3s ease;

            img {
                height: 130px;
                max-width: 100%;
                opacity:0;
            }

            .infra-image {
                opacity: 1;
                transition: opacity 0.3s ease;
            }

            .infra-image-hover,
            .infra-image-disabled {
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
                opacity: 0;
                transition: opacity 0.3s ease;
            }
        }

        &-label {
            color: white;
            text-align: center;
            font-weight: 500;
            font-size: 1.2rem;
            padding: 20px;
        }

        &:hover {
            cursor: pointer;
            -webkit-box-shadow: inset 0px 0px 5px @font-color-base;
            -moz-box-shadow: inset 0px 0px 5px @font-color-base;
            box-shadow: inset 0px 0px 5px @font-color-base;

            .infra-images {
                background: transparent;
            }

            .infra-image {
                opacity: 0;
                transition: opacity 0.3s ease;
            }

            .infra-image-hover {
                opacity: 1;
                transition: opacity 0.3s ease;
            }
        }

        &.disabled {
            cursor: not-allowed;

            .infra-images {
                opacity: 0.3;                
            }

            .infra-image-hover .infra-image {
                display: none;
            }
        
            }

        @media screen and (max-width: 768px) {
            flex: 35%;
        }

        @media screen and (max-width: 600px) {
            flex: 100%;
        }
    }
}

.infraestructuras {
    padding: 0;

    #dvInfraestructuras {
        clear: both;
        padding: 0 15px 0;
        margin-right: 0;
        padding-right: 0;
    }
}

.infraestructuras-mapa {
    height: calc(100vh - 65px); // habrá que restar el header
    background-color: whitesmoke;
    padding: 0;

    .container {
        width: 100%;
    }

    #map_canvas,
    .gmap_canvas,
    .mapouter,
    iframe {
        height: 100% !important;
        width: 100% !important;
    }
}

.infraestructuras-lista {
    overflow-y: scroll;
    max-height: calc(100vh - 65px); // habrá que restar el header

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.1);
        border-radius: 10px;
        background-color: #F0EFFF;
    }

    &::-webkit-scrollbar {
        width: 15px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.1);
        background-color: @color-primary;
    }

    ul.infraestructura-boxes {
        list-style: none;
        padding-left: 0;

        li {
            padding-bottom: 15px;
            padding-top: 15px;
            padding-left: 15px;
            border-bottom: 1px solid #F0EFFF;
            transition: all .3s;

            &:hover {
                cursor: pointer;
                background-color: @color-primary-xlight;
            }
        }

        .infraestructura-box {
            display: flex;

            .infraestructura-box-image {
                width: 100%;
            }

            .infraestructura-box-datos {
                display: flex;
                flex-direction: column;
                padding-left: 0;
                padding-right: 30px;
            }

            &-CodIdentificador {
                text-align: center;
                background: @color-ok-light;
                color: @color-ok;
                border-radius: 15px;
                padding: 5px 0px 0px;
                font-size: 1rem;
                margin-bottom: 10px;
                font-weight: bold;
                text-transform: uppercase;
            }

            &-Descripcion {
                margin-bottom: 20px;
                flex: auto;
            }

            &.infraestructura-status-0 {
                .infraestructura-box-CodIdentificador {
                    background: @color-ok-light;
                    color: @color-ok;
                }
            }

            &.infraestructura-status-1 {
                .infraestructura-box-CodIdentificador {
                    background: @color-warning-light;
                    color: @color-warning;
                }
            }

            &.infraestructura-status-2 {
                .infraestructura-box-CodIdentificador {
                    background: @color-fail-light;
                    color: @color-fail;
                }
            }
        }
    }
}



.btn-float {
	position:fixed;
	width:50px;
	height:50px;
	bottom:35px;
	right:35px;
	background-color: @color-primary;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    box-shadow: 2px 2px 3px #0404046e;
    font-size: 1.1rem;
    line-height: 50px;
    display: none;
}

.sensores-wrapper {
    .btn-float {
        bottom: 65px;
        display: none;
    }
}

.btn-float-map {
    background-color: @color-primary;
    padding: 5px 10px;
    border-radius: 30px;
    color: white;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 15px;
    font-size: 1.1rem;
    display: none;
    box-shadow: 2px 2px 3px #0404046e;

    span {
        padding-left: 5px;
        color: @color-secondary;
        font-size: 1rem;
        font-weight: bold;
    }
}

@media screen and (max-width: 600px) {
    .infraestructuras {
        overflow-x: hidden;
    }

    .infraestructuras-lista {
        overflow-y: auto;
        max-height: none;

        ul.infraestructura-boxes {
            .infraestructura-box {
                flex-direction: column;
                padding-bottom: 0;

                .infraestructura-box-imagen {
                    width: 100%;
                    margin-bottom: 20px;
                }

                .infraestructura-box-datos {
                    padding: 15px;
                }
            }
        }
    }

    .infraestructuras-mapa,
    .sensores-mapa {
        height: 0;
        max-height: 0;
        position: fixed;
        width: 100%;
        overflow: hidden;
        bottom: 0;
        transition: all 0.5s;
        padding: 15px 0 0;
        background: transparent;

        .gmap_canvas,
        .mapouter,
        iframe {
            height: 70vh !important;
        }

        &.active {
            height: 70vh;
            max-height: 70vh;

            .btn-float-map {
                display: block;
            }
        }
    }

    .sensores-wrapper .btn-float,
    .btn-float {
        display: block;
    }
}