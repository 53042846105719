.form-signin {
  img {
    margin: 0 auto;
    margin-bottom: 40px;
  }

  button {
    background: rgb(35,28,134);
    background: linear-gradient(180deg, rgba(35,28,134,1) 0%, rgba(28,22,111,1) 100%);
    border: none;
    -webkit-box-shadow: 3px 3px 6px 2px rgba(0,0,0,0.45); 
    box-shadow: 3px 3px 6px 2px rgba(0,0,0,0.45);
    padding: 18px;
    margin-top: 30px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.8px;
    font-size: 1.1rem;

    &:hover {
      -webkit-box-shadow: inset 0px 0px 5px @font-color-base;
     -moz-box-shadow: inset 0px 0px 5px @font-color-base;
          box-shadow: inset 0px 0px 5px @font-color-base;
    }
  }

  .input-group {
    border-bottom: 1px solid white;
    margin-bottom: 15px;

    .input-group-addon {
      background: transparent;
      border: none;

      > span {
        color: @color-secondary;
      }
    }

    input.form-control {
      background: transparent;
      box-shadow: none;
      color: @font-color-light;
      font-weight: bold;
      padding-bottom: 7px;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: @font-color-light;;
        opacity: 1; /* Firefox */
      }
      
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: @font-color-light;;
      }
      
      &::-ms-input-placeholder { /* Microsoft Edge */
        color: @font-color-light;;
      }
    }
  }
}

.form-edit input {
    margin-bottom: 5px;
    color: @font-color-dark;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 10px;
    width: 100%;
    background-color: #eaeaea;
}

.form-edit select {
  margin-left: 10px;
  width: 100%;

  -webkit-appearance: none;
  height: 40px !important;
  line-height: 40px !important;
  color: @font-color-dark;
  padding-left: 13px;
  border-radius: 0;
  background: @bg-color-light;
  margin-bottom: 5px;
  border: none;
}

.form-edit button.btn {
  margin-top: 15px;
}

.form-edit .btn-default[disabled] {
  color: @font-color-dark;
}

.form-edit span.help {
  margin-left: 10px;
  margin-bottom: 20px;
  display: block;
  font-size: 14px;
}

.form-edit-table {
  display: table;
  width: 100%;
}

.form-edit-row {
  display:table-row;
}

.form-edit-name {
  display:table-cell;
  width: 20%;
  min-width: 150px;
}

.form-edit-value {
  display:table-cell;
  width: 80%;
}

.form-edit-name-xl {
  display:table-cell;
  width: 60%;
}

.form-edit-value-xs {
  display:table-cell;
  width: 40%;
}

.form-edit-value input,
.form-edit-value select {
  margin-left: 0;
}