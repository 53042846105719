﻿.sensores {
    max-height: calc(100vh - 70px - 70px - var(--infra-header));
}

.sensores-wrapper {
    display: flex;
    flex: 1;
    height: 100%;

    .sensores-container {
        width: 100%;

        .sensores-lista {
            overflow-y: scroll;
            // max-height: calc(100vh - 65px); // habrá que restar el header
            max-height: calc(100vh - 70px - 70px - var(--infra-header));

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.1);
                border-radius: 10px;
                background-color: #F0EFFF;
            }

            &::-webkit-scrollbar {
                width: 15px;
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.1);
                background-color: @color-primary;
            }
        }

        .sensores-mapa {
            // height: calc(100vh - 65px); // habrá que restar el header
            background-color: whitesmoke;
            padding: 0;
            height: 100%;

            > div,
            #map_canvas,
            .gmap_canvas,
            .mapouter,
            iframe {
                height: 100% !important;
                width: 100% !important;
            }
        }

        @media screen and (max-width: 600px) {
            padding: 0;
        }
    }
}

.nodo {
    background: @color-primary-xlight;
    padding: 10px;
    margin-bottom: 20px;

    h3 {
        margin-top: 10px;
    }

    .nodo-charts {
        margin-top: 20px;

        zingchart > div {
            margin: 0 auto 10px;
            max-width: 100%;

            // > div {
            //     max-width: 100%;
            // }
        }
    }
}

.nodo-data {
    display: flex;
    align-items: center;
    padding-left: 20px;

    label, output {
        margin: 0;
        padding: 0;
    }

    label {
        margin-right: 10px;
    }
}