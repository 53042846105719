//.angular-google-map-container {
//  height: 100%;
//  width: 100%;
//  position: absolute;
//}
.angular-google-map-container {
  height: 500px;
  width: 100%;
  position: relative;
}
.maps-url {
  width: 100%;
  display: block;
  padding: 5px 10px;
  border: 1px solid @color-primary;
  text-align: center;
  margin-top: 5px;
}