.slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.slide.ng-enter,
.slide.ng-leave {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.slide.ng-enter {
  left: 100%;
}
.slide.ng-enter-active {
  left: 0;
}
.slide.ng-leave {
  left: 0;
}
.slide.ng-leave-active {
  left: -100%;
}