#body_wrapper {
  min-height: 100vh;
  position: relative;
}

.main-content {
  padding-top: 50px;
  padding-bottom: 80px;
}

.view {
  margin-top: 90px;
  padding-top: 20px;
}

.margin-top-content {
  padding-top: 50px;
}

.margin-bottom-content {
  padding-bottom: 50px;
}

.posts {
  height: 100%;
}

.app-body.to-top {
  padding-top: 0 !important;
}


@media (max-width: @screen-sm - 1) {
  .container {
    width: 100%;
  }
}

.login-page {
  background-image: url(../images/bg-login.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;

  .container--login {
    max-width: 420px;
  }
}

.infraestructuras-page {
  background-image: url(../images/bg-seleccion.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  
  .container--infraestructuras {
  
  }
}
