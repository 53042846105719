input {
  border-radius: 0 !important;
  border: none !important;
  height: 40px !important;
}

a {
  color: @color-primary;
}

h3 {
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  h1 {
    font-size: 24px;
    padding: 15px 0 10px;
  }
}