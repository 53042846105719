.login {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  padding: 50px;
  text-align: center;
  z-index: 1;

  form {
    padding: 40px;
    background-color: @bg-color-light;
    max-width: 420px;
    margin: 0 auto;
  }

  h1 {
    color: @color-primary;
    font-size: 15px;
    text-transform: none;
    font-weight: 600;
    margin-top: 0;
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/login-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .form-control {
    background-color: @bg-color-grey !important;
  }

  video {
    z-index: -1;
    background-image: url(../images/login_bg.jpg);
    clear: both;
    right: 0;
    bottom: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
  }

  .fields input {
    margin-bottom: 3px;
    background-repeat: no-repeat;
    background-position: 10px;
    padding-left: 40px;
    background-size: 20px;
  }
  .fields .username {
    background-image: url(../images/user_icon.png) !important;
  }
  .fields .password {
    background-image: url(../images/pass_icon.png) !important;
  }
}

.btn-login {
  height: 40px;
  margin-bottom: 3px;
}


@media (max-width: 480px) {
  .login {
    padding: 0;

    form {
      padding: 30px 10px;
      height: 100vh;

      img {
        max-width: 150px;
      }
    }
  }
}

.login-mobile {
  z-index: 9;
  position: fixed;
  background: @color-primary;
  width: 100%;
  padding: 15px;
  top: 50px;

  .navbar-form {
    box-shadow: none;
  }

  @media (min-width: @screen-sm) {
    display: none;
  }
}