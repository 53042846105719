@import "../themes/indi-variables.less";

.mt0 { margin-top: 0 !important; }
.mt10 { margin-top: 10px !important; }
.mt20 { margin-top: 20px !important; }
.mt30 { margin-top: 30px !important; }
.mt40 { margin-top: 40px !important; }
.mt100 { margin-top: 100px !important; }

.mb0 { margin-bottom: 0 !important; }
.mb10 { margin-bottom: 10px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb30 { margin-bottom: 30px !important; }
.mb40 { margin-bottom: 40px !important; }

.ml10 { margin-left: 10px !important; }
.ml20 { margin-left: 20px !important; }
.ml30 { margin-left: 30px !important; }
.ml40 { margin-left: 40px !important; }

.pt0 { padding-top: 0 !important; }
.pt10 { padding-top: 10px !important; }
.pt20 { padding-top: 20px !important; }
.pt30 { padding-top: 30px !important; }

.green {
  color: #6fbe6a;
}
.red {
  color: #c34442;
}
.black {
  color: #272c34;
}

.bordeRojo {
  border: solid 1px @color-alert
}

.destacado {
  background-color: @color-primary;
  text-transform: uppercase;
  padding: 3px;
}

.mostrar {
  visibility: visible;
}
.mostrar.mapa {
  top: 50px;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 50px;
}
.ocultar {
  visibility: hidden;
}
.hidden {
  display: none !important;
  visibility: hidden !important;
}

.tint-black {
  background-color: @bg-color-dark;
  position: relative;
}
.tint-black img {
  opacity: 0.6;
}
.tint-black .fa {
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  color: @font-color-light;
  font-size: 16px;
  top: 35%;
}

.marker-box {
  @color-@font-color-dark;
}

.clickable {
  cursor: pointer;
}