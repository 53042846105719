/*.indice{
    .indice-verde {
        color: @color-green;
    }

    .indice-naranja {
        color: @color-orange;
    }

    .indice-rojo {
        color: @color-alert;
    }
}*/

.indice .indice-verde {
    color: #00ff00;  
}

.indice .indice-naranja {
    color: #ffa500;  
}

.indice .indice-rojo {
    color: #ff0000;  
}
