.breadcrumb {
    clear: both;
    padding: 15px 30px;
    margin-bottom: 0;
    list-style: none;
    background-color: @color-primary;
    display: inline-block;

    > li {
        display: inline-block;
        color: @font-color-light;
        position: relative;
        padding: 0 10px;
        font-size: 1.1rem;

        a {
            color: @font-color-light;
        }

        + li {
            padding-left: 20px;
        }

        + li:before {
            content: "";
            border: 1px solid #3C1AFF;
            position: absolute;
            height: 40px;
            top: -10px;
            left: 0;
            padding: 0;
        }

        &.back {
            padding-left: 35px;

            &:before {
                content: '';
                background-image: url(../images/icon-back.svg);
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                left: 0;
                height: 40px;
                width: 40px;
                height: 20px;
                width: 30px;
            }
        }

        &.icon-tuneles,
        &.icon-puentes,
        &.icon-edar,
        &.icon-edificios,
        &.icon-industria,
        &.icon-presas,
        &.icon-tuneles-ferroviarios,
        &.icon-obras {
            padding-left: 70px;

            &:after {
                content: '';
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                left: 15px;
                height: 30px;
                width: 40px;
                top: -5px;
            }
        }

        &.icon-tuneles:after {
            background-image: url(../images/infra-tuneles-hover.svg);
        }

        &.icon-puentes:after {
            background-image: url(../images/infra-puentes-hover.svg);
        }

        &.icon-edar:after {
            background-image: url(../images/infra-edar-hover.svg);
        }

        &.icon-edificios:after {
            background-image: url(../images/infra-edificios-hover.svg);
        }

        &.icon-industria:after {
            background-image: url(../images/infra-industria-hover.svg);
        }

        &.icon-presas:after {
            background-image: url(../images/infra-presas-hover.svg);
        }

        &.icon-presas:after {
            background-image: url(../images/infra-presas-hover.svg);
        }

        &.icon-tuneles-ferroviarios:after {
            background-image: url(../images/infra-tuneles-ferrocarril-hover.svg);
        }

        &.icon-obras:after {
            background-image: url(../images/infra-obras-hover.svg);
        }

        &.active,
        &.active a {
            color: @font-color-light;
            font-weight: @font-weight-bold;
        }
    }
}

@media (max-width: @screen-xs - 1) {
  .breadcrumb {
    font-size: 14px;
    display: none;
  }
}