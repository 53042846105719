﻿.documentacion {
    padding-top: 20px;
    padding-bottom: 70px;

    .documentacion-path {
        font-size: 1.3rem;
        margin-bottom: 10px;
        font-weight: bold;
        display: inline;

        a {
            cursor: pointer;
        }

        @media screen and (max-width: 768px) {
            font-size: 1.1rem;
        }
    }

    .documentacion-explorer {
        margin-top: 10px;
    }

    .documentacion-files {
        list-style: none;
        background: @color-primary-xlight;
        padding: 10px 15px;

        li {
            display: flex;
            align-items: center;
            padding-left: 10px;
            transition: all 0.2s;

            input {
                margin-right: 10px;
            }

            a {
                padding-top: 8px;
                cursor: pointer;
                flex: 1;
            }

            &.folder {
                font-weight: bold;

                span {
                    margin-right: 5px;
                }
            }

            &:hover {
                background-color: darken(@color-primary-xlight, 5%);
            }
        }

        .metadata {
            padding-top: 10px;
        }
    }

    .documentacion-uploadfiles {
        padding: 10px;
        border: 2px solid @color-primary;
        margin-bottom: 20px;

        li {
            padding: 5px 0;
        }


        button.btn {
            background-color: @color-primary;
            color: white;
            border-radius: 0;
            border: none;

            span {
                color: @color-secondary;
                margin-right: 5px;
            }
        }
    }

    .metadata {
        margin: 0 20px 0 10px;
        color: grey;
        font-size: 12px;
    }
}

.btn-toolbar {
    margin-bottom: 20px;

    button.btn, label.btn {
        background-color: @color-primary;
        color: white;
        border-radius: 0;
        border: none;
        margin-right: 5px;

        span {
            color: @color-secondary;
            margin-right: 5px;
        }

        span.text {
            color: white;

            @media (max-width: 768px) {
                display: none;
            }
        }
    }
}

[hidden] {
    display: none !important;
}
