.tabs-header {
    background-color: @color-primary-xlight;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    ul.nav-tabs {
        display: flex;


        li {
            flex: 1;
            position: relative;
            display: flex;
            align-items: center;
            height: 70px;
            cursor: pointer;
            transition: all 0.2s;

            > span {
                padding: 20px 20px 20px 60px;
                display: block;
            }

            &:before {
                content: '';
                position: absolute;
                left: 10px;
                top: 18px;
                width: 40px;
                height: 35px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }

            &.tab-bim:before {
                background-image: url(../images/icon-bim.svg);
            }

            &.tab-nube:before {
                background-image: url(../images/icon-nube.svg);
            }

            &.tab-sensores:before {
                background-image: url(../images/icon-sensores.svg);
            }

            &.tab-ra:before {
                background-image: url(../images/icon-ra.svg);
            }

            &.tab-doc:before {
                background-image: url(../images/icon-doc.svg);
            }

            &.tab-parte:before {
                background-image: url(../images/icon-parte.svg);
            }

            &.tab-gis:before {
                background-image: url(../images/icon-gis.svg);
            }

            &.active,
            &:hover {
                background: @color-primary;
                color: @font-color-light;

                &.tab-bim:before {
                    background-image: url(../images/icon-bim-hover.svg);
                }

                &.tab-nube:before {
                    background-image: url(../images/icon-nube-hover.svg);
                }

                &.tab-sensores:before {
                    background-image: url(../images/icon-sensores-hover.svg);
                }

                &.tab-ra:before {
                    background-image: url(../images/icon-ra-hover.svg);
                }

                &.tab-doc:before {
                    background-image: url(../images/icon-doc-hover.svg);
                }

                &.tab-parte:before {
                    background-image: url(../images/icon-parte-hover.svg);
                }

                &.tab-gis:before {
                    background-image: url(../images/icon-gis-hover.svg);
                }
            }

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
                pointer-events: none;

                &.tab-bim:before {
                    background-image: url(../images/icon-bim-disabled.svg);
                }

                &.tab-nube:before {
                    background-image: url(../images/icon-nube-disabled.svg);
                }

                &.tab-sensores:before {
                    background-image: url(../images/icon-sensores-disabled.svg);
                }

                &.tab-ra:before {
                    background-image: url(../images/icon-ra-disabled.svg);
                }

                &.tab-doc:before {
                    background-image: url(../images/icon-doc-disabled.svg);
                }

                &.tab-parte:before {
                    background-image: url(../images/icon-parte-disabled.svg);
                }

                &.tab-gis:before {
                    background-image: url(../images/icon-gis-disabled.svg);
                }
            }
        }
    }
}

.tabs-content {
    .tab-title {
        display: none;
    }
}


.tab-content {
    &.tab-content--fullheight {
        min-height: calc(100vh - 70px);

        > .tab-pane.active {
            min-height: calc(100vh - 70px);
            display: flex;
            flex-direction: column;

            .tab-content-fh-content {
                // Realidad Virtual y Sensores
                &.rv,
                &.sensores {
                    flex: auto;
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        @media screen and (max-width: 768px) {
            min-height: calc(100vh - 85px);

            > .tab-pane.active {
                min-height: calc(100vh - 85px);
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .tabs-header {
        .container {
            padding: 0;
        }

        ul.nav-tabs {
            li {
                display: block;

                > span {
                    font-size: 0;
                }

                &:before {
                    width: 100%;
                    left: 0;
                }
            }
        }
    }

    .tabs-content {
        .tab-title {
            display: block;
            margin: 0 -15px;
            background: @color-primary;
            color: @font-color-light;
            text-align: center;
            padding: 7px 0 5px;
        }
    }
}

.tab-content #tabinv .row-row-eq-height-mt4 li,
.tab-content #tabinfo .row-row-eq-height-mt4 li,
.tab-content #tabcons .row-row-eq-height-mt4 li,
.tab-content #tabinsp .row-row-eq-height-mt4 li {
    background-color: @bg-color-grey;
    color: @color-primary;
    padding-left: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 15vw;
}

.tab-content #tabinv .container-body,
.tab-content #tabinfo .container-body,
.tab-content #tabcons .container-body,
.tab-content #tabinsp .container-body {
    display: flex;
}

.tab-content #tabinv .row-row-eq-height-mt4 li a,
.tab-content #tabinfo .row-row-eq-height-mt4 li a,
.tab-content #tabcons .row-row-eq-height-mt4 li a,
.tab-content #tabinsp .row-row-eq-height-mt4 li a {
    color: @color-primary;
}

.tab-content #tabinv .row-row-eq-height-mt4 li:hover,
.tab-content #tabinfo .row-row-eq-height-mt4 li:hover,
.tab-content #tabcons .row-row-eq-height-mt4 li:hover,
.tab-content #tabinsp .row-row-eq-height-mt4 li:hover {
    background-color: grey;
    color: white;
}

.tab-content #tabinv .row-row-eq-height-mt4 li.active,
.tab-content #tabinfo .row-row-eq-height-mt4 li.active,
.tab-content #tabcons .row-row-eq-height-mt4 li.active,
.tab-content #tabinsp .row-row-eq-height-mt4 li.active {
    background-color: @color-primary;
    color: white;

    .div-gliph {
        background-color: @color-primary-dark;
    }
}

.tab-content #tabinv .row-row-eq-height-mt4 li #div-gliph.active,
.tab-content #tabinfo .row-row-eq-height-mt4 li #div-gliph.active,
.tab-content #tabcons .row-row-eq-height-mt4 li #div-gliph.active,
.tab-content #tabinsp .row-row-eq-height-mt4 li #div-gliph.active {
    background-color: @color-primary;
    color: white;

    :hover {
        background-color: @color-primary-dark;
    }
}

.tab-content #tabinv .row-row-eq-height-mt4 ul li,
.tab-content #tabinfo .row-row-eq-height-mt4 ul li,
.tab-content #tabcons .row-row-eq-height-mt4 ul li,
.tab-content #tabinsp .row-row-eq-height-mt4 ul li {
    height: 100%;
    position: relative;
    //background-color: greenyellow;
}

.tab-content #tabinv .row-row-eq-height-mt4 ul li .div-gliph,
.tab-content #tabinfo .row-row-eq-height-mt4 ul li .div-gliph,
.tab-content #tabcons .row-row-eq-height-mt4 ul li .div-gliph,
.tab-content #tabinsp .row-row-eq-height-mt4 ul li .div-gliph {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #C2C2C2;
    width: 20%;
    display: flex;
}

.tab-content #tabinv .row-row-eq-height-mt4 ul,
.tab-content #tabinfo .row-row-eq-height-mt4 ul,
.tab-content #tabcons .row-row-eq-height-mt4 ul,
.tab-content #tabinsp .row-row-eq-height-mt4 ul {
    :not(.active):hover {
        background-color: #341BD6;

        .div-gliph {
            background-color: #0F1047;

            :not(.active):hover {
                background-color: #0F1047;
            }
        }

        div {
            background-color: #341BD6;

            :not(.active):hover {
                background-color: #341BD6;
            }
        }
    }

    .active:hover {
        background-color: @color-primary;

        .div-gliph {
            background-color: @color-primary-dark;
        }

        div-gliph.active:hover {
            background-color: @color-primary-dark;
        }

        div {
            background-color: @color-primary;

            :not(.active):hover {
                background-color: @color-primary;
            }
        }
    }
}

.container-fluid-infraestructura {
    margin-left: 0px;
    margin-right: 0px;
}



.tab-content #tabinv .row-row-eq-height-mt4 ul li .div-gliph .glyphicon-asterisk, .glyphicon-road, .glyphicon-fullscreen, .glyphicon-shopping-cart, .glyphicon-alert,
.tab-content #tabinfo .row-row-eq-height-mt4 ul li .div-gliph .glyphicon-asterisk, .glyphicon-road, .glyphicon-fullscreen, .glyphicon-shopping-cart, .glyphicon-alert,
.tab-content #tabcons .row-row-eq-height-mt4 ul li .div-gliph .glyphicon-asterisk, .glyphicon-road, .glyphicon-fullscreen, .glyphicon-shopping-cart, .glyphicon-alert,
.tab-content #tabinsp .row-row-eq-height-mt4 ul li .div-gliph .glyphicon-asterisk, .glyphicon-road, .glyphicon-fullscreen, .glyphicon-shopping-cart, .glyphicon-alert {
    height: 100%;
    width: 100%;
    display: flex;
    font-size: 18px;
    justify-content: center;
    align-items: center;
}

.tab-content #tabinv .row-row-eq-height-mt4,
.tab-content #tabinfo .row-row-eq-height-mt4,
.tab-content #tabcons .row-row-eq-height-mt4,
.tab-content #tabinsp .row-row-eq-height-mt4 {
    display: block;
}

.tab-content #tabinsp .container-body .container-tab-content .texto-radio {
    margin-top: 15px;
}

.tab-content #tabinv .row-row-eq-height-mt4 .sidebar,
.tab-content #tabinfo .row-row-eq-height-mt4 .sidebar,
.tab-content #tabcons .row-row-eq-height-mt4 .sidebar,
.tab-content #tabinsp .row-row-eq-height-mt4 .sidebar {
    padding-left: 0px;
}

.tab-content #tabinv .row-row-eq-height-mt4 .sidebar ul li div,
.tab-content #tabinfo .row-row-eq-height-mt4 .sidebar ul li div,
.tab-content #tabcons .row-row-eq-height-mt4 .sidebar ul li div,
.tab-content #tabinsp .row-row-eq-height-mt4 .sidebar ul li div {
    align-items: center;
    justify-content: center;
    align-self: stretch;
    display: flex;
}


.tab-content #tabinv .container-body .container-tab-content,
.tab-content #tabinfo .container-body .container-tab-content,
.tab-content #tabcons .container-body .container-tab-content,
.tab-content #tabinsp .container-body .container-tab-content {
    > .tab-pane {
        display: none;
    }

    > .tab-pane.active {
        min-height: calc(100vh - 70px);
        display: flex;
        flex-direction: column;

        .tab-content-fh-content {
            // Realidad Virtual y Sensores
            &.rv,
            &.sensores {
                flex: auto;
                display: flex;
                flex-direction: column;
            }
        }
    }

    @media screen and (max-width: 768px) {
        min-height: calc(100vh - 85px);

        > .tab-pane.active {
            min-height: calc(100vh - 85px);
        }
    }
}

.tab-content #tabinv .content-header,
.tab-content #tabinfo .content-header,
.tab-content #tabcons .content-header,
.tab-content #tabinsp .content-header {
    border-color: @bg-color-grey !important;
    display: flex;
    flex-wrap: wrap;
    height: 90px;
}

.tab-content #tabinv .content-header .actions--actions--small,
.tab-content #tabinfo .content-header .actions--actions--small,
.tab-content #tabcons .content-header .actions--actions--small,
.tab-content #tabinsp .content-header .actions--actions--small {
    width: 100%;
    //background-color:magenta;
    border-color: @bg-color-grey !important;
    border: solid;
    display: flex;
    flex-wrap: wrap;
}

.tab-content #tabinv .content-header ul,
.tab-content #tabinfo .content-header ul,
.tab-content #tabcons .content-header ul,
.tab-content #tabinsp .content-header ul {
    list-style-type: none;
    border-color: @bg-color-grey;
    border: solid;
}


.tab-content #tabinv .content-header .actions--actions--small .actions--left-actions--small,
.tab-content #tabinfo .content-header .actions--actions--small .actions--left-actions--small,
.tab-content #tabcons .content-header .actions--actions--small .actions--left-actions--small,
.tab-content #tabinsp .content-header .actions--actions--small .actions--left-actions--small {
    width: 7%;
    border-color: @bg-color-grey !important;
    border-right: solid;
    //font-size: 20px;
    font-size: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*.tab-content #tabinv .content-header .actions--actions--small .actions--left-actions--small .span-admin-tipot,
.tab-content #tabinsp .content-header .actions--actions--small .actions--left-actions--small .span-admin-tipot {
    font-size: 18px;
    display: flex;
    align-items: center;
    background-color:magenta;
}*/

.tab-content #tabinv .content-header .actions--actions--small .actions--center-actions--small,
.tab-content #tabinfo .content-header .actions--actions--small .actions--center-actions--small,
.tab-content #tabcons .content-header .actions--actions--small .actions--center-actions--small,
.tab-content #tabinsp .content-header .actions--actions--small .actions--center-actions--small {
    //width: 90%;
    //overflow: hidden;
    //border-right: solid;
    flex-grow: 1;
    padding-left: 50px;
    display: flex;
    align-items: center;
    //justify-content: center;
}

.tab-content #tabinv .content-header .actions--actions--small .actions--right-actions--small,
.tab-content #tabinfo .content-header .actions--actions--small .actions--right-actions--small,
.tab-content #tabcons .content-header .actions--actions--small .actions--right-actions--small,
.tab-content #tabinsp .content-header .actions--actions--small .actions--right-actions--small {
    width: 10%;
    border-color: @bg-color-grey !important;
    border-left: solid;
    //font-size: 20px;
    font-size: 0.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-content #tabinv .content-header .actions--actions--small .actions--right-actions--small .span-admin,
.tab-content #tabinfo .content-header .actions--actions--small .actions--right-actions--small .span-admin,
.tab-content #tabcons .content-header .actions--actions--small .actions--right-actions--small .span-admin,
.tab-content #tabinsp .content-header .actions--actions--small .actions--right-actions--small .span-admin {
    font-size: 16px;
}

.tab-content #tabinv .content-header .actions--actions--small .actions--right-actions--small .span-admin-tipot,
.tab-content #tabinfo .content-header .actions--actions--small .actions--right-actions--small .span-admin-tipot,
.tab-content #tabcons .content-header .actions--actions--small .actions--right-actions--small .span-admin-tipot,
.tab-content #tabinsp .content-header .actions--actions--small .actions--right-actions--small .span-admin-tipot {
    font-size: 12px;
    display: flex;
    align-items: center;
}

.tab-content #tabinv .content-header .actions--actions--small .actions--right-actions--small .span-admin-tipot .glyphicon,
.tab-content #tabinfo .content-header .actions--actions--small .actions--right-actions--small .span-admin-tipot .glyphicon,
.tab-content #tabcons .content-header .actions--actions--small .actions--right-actions--small .span-admin-tipot .glyphicon,
.tab-content #tabinsp .content-header .actions--actions--small .actions--right-actions--small .span-admin-tipot .glyphicon {
    padding-right: 10px;
}


.tab-content #tabinv .content-header .actions--actions--small .actions--right-actions--small:hover,
.tab-content #tabinfo .content-header .actions--actions--small .actions--right-actions--small:hover,
.tab-content #tabcons .content-header .actions--actions--small .actions--right-actions--small:hover,
.tab-content #tabinsp .content-header .actions--actions--small .actions--right-actions--small:hover {
    width: 10%;
    border-left: solid;
    border-color: @bg-color-grey !important;
    //font-size: 20px;
    font-size: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @color-primary;
    color: white;
}

.tab-content #tabinv .form-control,
.tab-content #tabinfo .form-control,
.tab-content #tabinv .form-control,
.tab-content #tabcons .form-control {
    background-color: @bg-color-grey !important;
    color: @color-primary;
}

.tabs-content .container-body .container-row {
    justify-content: center; /* Centra verticalmente los elementos en el contenedor */
    align-items: flex-start; /* Alinea todos los elementos al inicio (izquierda) */
    //background-color: magenta;
    display: inline;
    margin: 30px;
    padding: 30px;
    width: 30%;
    height: 100%;
    flex-direction: column; /* Columnas (por defecto) */
    .col-xs-8, .col-xs-4, .col-xs-12 {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.tabs-content .container-body .graficos {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: row; /* Filas (por defecto) */
    justify-content: space-between; /* Espaciados entre elementos */
    //align-items: center; /* Centra verticalmente */
    text-align: center; /* Centra texto e inline elements dentro del div */
    h2 {
        white-space: nowrap;
        text-align: center;
    }

    .grafico-tarta {
        width: 100%;
        height: 100%;
        margin: 10px;
        padding: 10px;
        //border: 10px solid black; /* Ancho, estilo y color del borde */
        canvas {
            width: 100%;
            height: 100%;
        }
    }
}

/*.grafico-tarta {
    width: 50;
    height: 100%;
    margin: 10px;
    padding: 10px;
    //border: 10px solid black; /* Ancho, estilo y color del borde */
/*canvas, svg {
        width: 100%;
        height: 100%;
    }
}*/
